<template>
	<section class="wrapper-section" v-background="background">
		<base-container>
			<cms-text class="title" :props="{size: 'xl', color: 'white', weight: 'bold', uppercase: true}" :value="`${prefix}.title`"/>
			<div class="content-wrapper" v-animate="`slideRight`">
				<client-only>
					<cms-text :props="{color: 'white'}" :value="`${prefix}.destiny_text`"/>
				</client-only>
			</div>
			<div class="bottom-bar" v-animate="`opacity`">
				<div>
					<base-font class="bottom-bar-texts" size="lg" weight="bold" color="white" uppercase>
						{{langs.choice}}
					</base-font>
					<base-font class="bottom-bar-texts" size="lg" color="white" uppercase>
						{{langs.advice}}
					</base-font>
				</div>
				<div class="buttons-wrapper">
					<base-button class="category-button" type="secondary" icon="phone" :url="tel">{{langs.visit}}</base-button>
					<base-font color="white">{{langs.or}}</base-font>
					<base-button class="category-button send" type="primary" icon="send" to="contact">{{langs.contact}}</base-button>
				</div>
			</div>
		</base-container>
	</section>
</template>
<script>
import ClientOnly from 'vue-client-only'

export default {
	props: {
		prefix: String,
		value: Object
	},
	components: {
		ClientOnly
	},
	computed: {
		background () {
			const path = this.value.background_image.path

			return {
				src: path,
				breakpoints: {
					base: { width: 360, height: 650 },
					lg: { width: 825, height: 451 },
					xl: { width: 1920, height: 778 }
				}
			}
		},
		langs () {
			return this.$app.translator.get('products')
		},
		tel () {
			return `tel:${this.$app.settings.general.contact.phoneNumber}`
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	}
}
</script>
<style lang="scss" scoped>
.wrapper-section {
	position: relative;
    padding: 4rem 0 0;
    @include media-breakpoint-up(xl) {
        padding: 8rem 0 0;
        overflow: hidden;
    }
	z-index: 1;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.6) 100%);
		z-index: -1;
		@include media-breakpoint-up(xl) {
			background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 75%);
		}
	}
	::v-deep .title {
		margin-bottom: 4rem;
	}
}
.content-wrapper {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	width: 100%;
	@include media-breakpoint-up(xl){
		width: 60%;
	}
}
.bottom-bar {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 6rem 0;
	z-index: 1;
	margin-top: 5rem;
	@include media-breakpoint-up(xl) {
		flex-direction: row;
        justify-content: flex-start;
	}
    @include media-breakpoint-up(xxl){
        justify-content: space-between;
    }
	::v-deep &-texts {
		margin-bottom: 0;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100vw;
		height: 100%;
		background-color: rgba($primary, .9);
		z-index: -1;
	}
	.buttons-wrapper {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 4rem;
		align-items: center;
		@include media-breakpoint-up(md) {
			flex-direction: row;
			align-items: center;
			gap: 6rem;
            width: 100%;
            justify-content: center;
		}
        @include media-breakpoint-up(xl) {
            margin-top: 0;
            justify-content: initial;
            width: auto;
            margin-left: 2rem;
        }
        @include media-breakpoint-up(xxl){
            margin-left: 0;
        }
	}
}

</style>
